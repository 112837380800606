import { Link } from "react-router-dom";
import { Roles } from "../constants/CommonEnum";
import { EinfIcon } from "../assets/icons/EinfIcon";
import { UsersIcon } from "../assets/icons/UsersIcon";
import { OrganizationIcon } from "../assets/icons/OrganizationIcon";
import { ConversionFactorIcon } from "../assets/icons/ConversionFactorIcon";
import { Spin } from "antd";

export const BaseNavbar = [
  {
    key: "report",
    icon: <EinfIcon color="#E7B618" style={{ width: '22px' }} />,
    label: 'report',
    children: [
      {
        key: "EINF_SCREEN",
        roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
        label: <Spin />
      },
    ]
  },
  {
    key: "ORGANIZATION_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <OrganizationIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/organization`}>
        organization
      </Link>
    ),
  },
  {
    key: "MY_ORGANIZATION_SCREEN",
    roles: [Roles.ADMIN_ORGANIZATION],
    icon: <OrganizationIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/my-organization`}>
        myOrganization
      </Link>
    ),
  },
  {
    key: "CONVERSION_FACTOR_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <ConversionFactorIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/conversion-factor`}>
        conversionFactor
      </Link>
    ),
  },
  {
    key: "TEMPLATE_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <EinfIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/template`}>
        template
      </Link>
    ),
  },
  {
    key: "USER_SCREEN",
    roles: [Roles.SUPERADMIN, Roles.ADMIN_CROWE],
    icon: <UsersIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/user`}>
        user
      </Link>
    ),
  },
  {
    key: "MY_USER_SCREEN",
    roles: [Roles.ADMIN_ORGANIZATION],
    icon: <UsersIcon color="#E7B618" style={{ width: '22px' }} />,
    label: (
      <Link className="nav-link" to={`/my-user`}>
        user
      </Link>
    ),
  },
];

